import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { snakeToPascal } from '../../utils/caseconverters';
import styles from './StreamField.module.scss';

const ImageGallery = dynamic(() => import('../ImageGallery'));
const Faq = dynamic(() => import('../Faq'));
const RawHtml = dynamic(() => import('../RawHtml'));
const ArticleList = dynamic(() => import('../ArticleList'));
const Cards = dynamic(() => import('../Cards'));
const Quote = dynamic(() => import('../Quote'));
const PlaceSection = dynamic(() => import('../PlaceSection'));
const PostSegment = dynamic(() => import('../PostSegment'));
const ShortFacts = dynamic(() => import('../ShortFacts'));
const CardFact = dynamic(() => import('../CardFact'));
const VideoSegment = dynamic(() => import('../VideoSegment'));
const Map = dynamic(() => import('../Map'));
const CTA = dynamic(() => import('../CTA'));
const CampaignPreamble = dynamic(() => import('../CampaignPreamble'));
const CampaignText = dynamic(() => import('../CampaignText'));
const CampaignPostSegment = dynamic(() => import('../CampaignPostSegment'));
const CampaignCards = dynamic(() => import('../CampaignCards'));
const CampaignVideoSegment = dynamic(() => import('../CampaignVideoSegment'));
const CampaignImageGallery = dynamic(() => import('../CampaignImageGallery'));
const CampaignCTA = dynamic(() => import('../CampaignCTA'));
const CampaignEmbedded = dynamic(() => import('../CampaignEmbedded'));
const CampaignFaq = dynamic(() => import('../CampaignFaq'));
const CampaignModal = dynamic(() => import('../CampaignModal'));
const CampaignGrid = dynamic(() => import('../CampaignGrid'));
const CampaignDupes = dynamic(() => import('../CampaignDupes'));

const Components = {
    ImageGallery: ImageGallery,
    FaqBlock: Faq,
    RichText: RawHtml,
    ArticleList: ArticleList,
    Cards: Cards,
    Quote: Quote,
    PlaceSection: PlaceSection,
    PostSegment: PostSegment,
    ShortFacts: ShortFacts,
    FactBox: CardFact,
    VideoSegment: VideoSegment,
    Map: Map,
    Cta: CTA,
    CampaignPreamble: CampaignPreamble,
    CampaignText: CampaignText,
    CampaignPostSegment: CampaignPostSegment,
    CampaignCards: CampaignCards,
    CampaignVideoSegment: CampaignVideoSegment,
    CampaignImageGallery: CampaignImageGallery,
    CampaignCta: CampaignCTA,
    CampaignEmbedded: CampaignEmbedded,
    CampaignFaq: CampaignFaq,
    CampaignModal: CampaignModal,
    CampaignGrid: CampaignGrid,
    CampaignDupes: CampaignDupes,
};

class StreamField extends React.PureComponent {
    render() {
        const { items, pageType } = this.props;

        if (!items.length) {
            return null;
        }

        const dynamicComponents = items.map((item, index) => {
            item.component = snakeToPascal(item.type);
            const Component = Components[item.component];

            if (!Component) {
                return null;
            }

            const colors = item?.value?.colors || null;

            const classes = classNames(
                styles['StreamField__Component'],
                styles[`StreamField__Component--${item.component}`],
                {
                    [styles['StreamField__Component--FirstItem']]: index === 0,
                    [styles[`StreamField__Component--${colors}`]]: colors,
                }
            );

            return (
                <div key={index} id={item.id} className={classes}>
                    <Component
                        id={item.id}
                        pageType={pageType}
                        {...item.value}
                    />
                </div>
            );
        });

        return <div className={styles['StreamField']}>{dynamicComponents}</div>;
    }
}

StreamField.propTypes = {
    items: PropTypes.array,
    pageType: PropTypes.string,
};

StreamField.defaultProps = {
    items: [],
};

export default StreamField;
